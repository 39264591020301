import React from "react"
import { Link } from "gatsby"
//import * as styles from './weapon-selector.module.scss'
import { selectorContainer, weaponDropdownBtn, selectorLabel, dropdownItemContainer, weaponDropdownItem } from './weapon-selector.module.scss'

var Utils = require("../utils/utils")

class WeaponSelector extends React.Component {
  constructor(props) {
    super(props);
    
    // console.log(this.props.weapons)
    this.state = {
      showOptions: false,
    }

    this.toggleDropdownView = this.toggleDropdownView.bind(this)
  }

  toggleDropdownView(event) {
    event.preventDefault();
    this.setState(prevState => ({ showOptions: !prevState.showOptions}));
  }

  render() {
    return (
    <div className={ selectorContainer }>
      <div>
        <button className={weaponDropdownBtn}
          onClick={this.toggleDropdownView} 
          style={{backgroundImage: "url(" + this.props.currentWeaponIconUrl + ")"}} >
          <h3 
            className={selectorLabel} > 
            { this.props.currentWeaponName }
          </h3>
        </button>
      </div>
      <div style={{position: 'relative'}}>
        <div className={dropdownItemContainer}>
        { this.state.showOptions //|| true
         ? ( 
        this.props.weapons.filter(w=> w.name !== this.props.currentWeaponName ).map(( weapon, i ) => (
          <div key={weapon.id}>
          <Link
            to={Utils.CreateHeatmapSlug( 
                  this.props.mapName,
                  weapon.name, 
                  this.props.mapType )} >
              <button className={weaponDropdownItem}
                style={{backgroundImage: "url(" + weapon.smallIconImageUrl+ ")"}} >
                <h3 
                  className={selectorLabel} > 
                  { weapon.name }
                </h3>
              </button>
            </Link>
          </div>
        ))) : ( null )}
        </div>
      </div>
    </div>
    );
  }
}

export default WeaponSelector
