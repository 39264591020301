import React from "react"
import { Link } from "gatsby"
import { selectorContainer, mapTypeBtnSelected, mapTypeBtn, label } from './map-type-selector.module.scss'

var Utils = require("../utils/utils")

var mapTypes = [
  {
    'name': 'Kills',
    'label': 'Kills from',
    'description': 'Kills from this weapon',
  },
  {
    'name': 'Deaths',
    'label': 'Deaths at',
    'description': 'Deaths at these locations, by this weapon',
  },
  {
    'name': 'Sightlines',
    'label': 'Sightlines',
    'description': '',
  },
]

class MapTypeSelector extends React.Component {
  constructor(props) {
    super(props);
    //console.log(mapTypes)
    
    this.state = {
      showOptions: false,
    }
  }

  render() {
    return (
    <div className={ selectorContainer }>
      <div>
        {mapTypes.map( (map_type, i) => (
        <Link
          key={map_type.name}
          to={Utils.CreateHeatmapSlug(
                this.props.currentMapName,
                this.props.currentWeaponName,
                map_type.name)} >
          <button 
            className={
                map_type.name.toLowerCase() === this.props.currentMapType.toLowerCase() ? (mapTypeBtnSelected) : (mapTypeBtn)
            } >
            <h5 className={label}>
              {map_type.label}
            </h5>
          </button>
        </Link>
        ))}
      </div>
    </div>
    );
  }
}

export default MapTypeSelector
