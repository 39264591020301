import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import MapSelector from "../components/map-selector.js"
import WeaponSelector from "../components/weapon-selector.js"
import MapTypeSelector from "../components/map-type-selector.js"

const GlobalHeatmap = ({ data }) => {
  const map_data = data.file
  const mapsForThisWeapon = data.mapsForThisWeapon
  const weaponsForThisMap = data.weaponsForThisMap
  const thisWeapon = data.thisWeapon
  return (
    <Layout>
      <div>

        <MapSelector 
         maps={mapsForThisWeapon.edges}
         weapon={map_data.fields.weaponName}
         mapType={map_data.fields.mapType} 
         currentMapName={map_data.fields.mapName}
         currentMapUrl={weaponsForThisMap.imageUrl}/>

        <WeaponSelector 
         weapons={weaponsForThisMap.weapons}
         mapName={map_data.fields.mapName}
         mapType={map_data.fields.mapType}
         currentWeaponName={map_data.fields.weaponName}
         currentWeaponIconUrl={thisWeapon.smallIconImageUrl} />

        <MapTypeSelector
         currentMapName={map_data.fields.mapName}
         currentWeaponName={map_data.fields.weaponName}
         currentMapType={map_data.fields.mapType}/>

        <GatsbyImage 
          image={map_data.childImageSharp.gatsbyImageData}
          alt=''
          loading='eager' />

      </div>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!, $mapName: String!, $weaponName: String!) {

  file (
    fields: { slug: {eq: $slug}}
  ) {
      fields {
        slug
        mapName
        weaponName
        mapType
      }
      relativePath
      absolutePath
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }

  mapsForThisWeapon: allHeatmapManifestJson (
    sort: {fields: name, order: ASC},
    filter: {
      name: {ne: $mapName}  
      weapons: {elemMatch: {name: {eq: $weaponName}}}
    },
  ) {
      edges {
        node {
          name
          imageUrl
          id
        }
      }
    }

  weaponsForThisMap: heatmapManifestJson (
    name: {eq: $mapName}
  ) {
      imageUrl
      weapons {
        id
        name
        smallIconImageUrl
      }
    }

  thisWeapon: weaponDataJson(
    name: {eq: $weaponName}
  ) {
    name
    smallIconImageUrl
    id
  }
}
`
export default GlobalHeatmap