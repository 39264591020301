/*export default function PrettySlug(slug) {
    return slug.replace(/ /g, '_').toLowerCase()
}

export default function CreateHeatmapSlug(mapName, weaponName, mapType) {
    var url = mapName + '/' + weaponName + '/' + mapType
    return PrettySlug(url)
}

*/
function PrettySlug(slug) {
    return slug.replace(/ /g, '_').toLowerCase()
}

function CreateHeatmapSlug(mapName, weaponName, mapType) {
    var url = '/' + mapName + '/' + weaponName + '/' + mapType
    return PrettySlug(url)
}

module.exports = {
  PrettySlug: PrettySlug,
  CreateHeatmapSlug: CreateHeatmapSlug
}


/*
module.exports = {
  PrettySlug: function(slug) {
    return slug.replace(/ /g, '_').toLowerCase()
  },
  CreateHeatmapSlug: function(mapName, weaponName, mapType) {
    var url = mapName + '/' + weaponName + '/' + mapType
    return PrettySlug(url)
  }
}
*/
